import axios from "axios";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { useIsAuthenticated } from "react-auth-kit";
import { Card } from "react-bootstrap";
import { Link, useSearchParams, useNavigate } from "react-router-dom";
import { SlEnergy } from "react-icons/sl";
import { FaEuroSign } from "react-icons/fa";
import { BsArrowLeft } from "react-icons/bs";
import { LuAlignVerticalDistributeStart } from "react-icons/lu";
import "./Company.css";
import { env } from "../env"

const Company = () => {
  const navigate = useNavigate();
  const isAuthenticated = useIsAuthenticated();
  let [searchParams, setSearchParams] = useSearchParams();

  const [companyDataLoaded, setCompanyDataLoaded] = useState(false);
  const [companyData, setCompanyData] = useState([]);

  const [plantsDataLoaded, setPlantsDataLoaded] = useState(false);
  const [plantsData, setPlantsData] = useState([]);
  const [plantsView, setPlantsView] = useState([]);

  const getTotalProduction = () => {
    let total = 0;
    plantsData.forEach((plant) => (total += plant.mwh));
    return total;
  };

  const getTotalRevenue = () => {
    let total = 0;
    plantsData.forEach((plant) => (total += plant.eur));
    return total;
  };

  const config = {
    headers: {
      Token: Cookies.get("auth"),
    },
  };

  async function GetCompany() {
    if (isAuthenticated()) {
      try {
        const companyResponse = await axios.get(
          env.REACT_APP_API_BASE +
            "/GetCompanies?id=" +
            searchParams.get("id"),
          config
        );
        const companyData = companyResponse.data[0];

        const plantsResponse = await axios.get(
          env.REACT_APP_API_BASE +
            "/GetPlants?companyId=" +
            searchParams.get("id"),
          config
        );
        const plantsData = plantsResponse.data;

        const today = new Date();
        const monthString =
          today.getMonth() < 9
            ? "0" + (today.getMonth() + 1)
            : today.getMonth() + 1;
        const yearString = today.getYear() + 1900;
        const monthYearString = monthString + "/" + yearString;
        console.log(monthString);
        const productionPromises = plantsData.map(async (plant) => {
          const url =
            env.REACT_APP_API_BASE +
            "/GetProduction?plantId=" +
            plant.id +
            "&type=monthlySummary&parameter=" +
            monthYearString;
          try {
            const response = await axios.get(url, config);
            return response; // Return the entire response object
          } catch (error) {
            console.error("Error fetching production data:", error);
            navigate("/login?retry=true");
          }
        });

        const productionResponses = await Promise.all(productionPromises);
        console.log(productionResponses)
        const updatedPlantsData = plantsData.map((plant, index) => {
          if (productionResponses[index].data.length === 0) {
            return {
              eventTimestamp: "2023-09-07T05:00:00",
              id: parseInt(productionResponses[index].config.url.split("GetProduction?plantId=")[1].split("&")[0]),
              mwh: 0,
              eur: 0,
              power: 0,
            };
          }
          const productionData = productionResponses[index].data[0];
          return {
            ...plant,
            eur: productionData.eur,
            mwh: productionData.mwh,
          };
        });

        setCompanyData(companyData);
        setCompanyDataLoaded(true);
        setPlantsData(updatedPlantsData);

        const plantsView = updatedPlantsData.map((plant) => (
          <Card
            className="w-100 mb-3 custom-card"
            as={Link}
            to={"/plant?id=" + plant.id}
            key={plant.id}
          >
            <Card.Body>
              <div className="d-flex flex-row">
                <div className="plant-image-container">
                  <img
                    src="/sample-plant.jpg"
                    className="plant-image"
                    alt={plant.name}
                  />
                </div>
                <div className="plant-details">
                  <Card.Title className="plant-name">{plant.name}</Card.Title>
                  <div className="row plant-stats ps-1">
                    <div className="col-4 col-md-3 align-items-center justify-content-center p-0">
                      <LuAlignVerticalDistributeStart />{" "}
                      <p>{plant.installedCapacity} kW</p>
                    </div>
                    <div className="col-4 col-md-3 align-items-center justify-content-center p-0">
                      <SlEnergy /> <p>{plant.mwh.toFixed(2)} MWh</p>
                    </div>
                    <div className="col-4 col-md-3 align-items-center justify-content-center p-0">
                      <FaEuroSign /> <p>{plant.eur.toFixed(0)} EUR</p>
                    </div>
                    <div className="col-12 col-md-3 align-items-center justify-content-center p-0">
                      <span style={{ fontSize: "14px" }}>Status:</span>
                      <span
                        className="btn btn-outline-success disabled px-2 py-1 mx-2"
                        style={{
                          width: "60px",
                          height: "30px",
                          fontSize: "12px",
                          textAlign: "center",
                        }}
                      >
                        Active
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-100 d-flex flex-row align-items-center justify-content-center mt-2"></div>
            </Card.Body>
          </Card>
        ));

        setPlantsView(plantsView);
        setPlantsDataLoaded(true);
      } catch (error) {
        console.error("Error fetching data:", error);
        navigate("/login?retry=true");
      }
    }
  }

  useEffect(() => {
    try {
      GetCompany();
    } catch {
      return <p>Sorry, an error occured</p>;
    }
  }, []);

  return (
    <div className="bg-body-tertiary m-0 p-0">
      <div className="container-fluid w-100 d-flex flex-column align-items-center text-center mt-0 p-0">
        {companyDataLoaded ? (
          companyData.length === 0 ? (
            <h1>The company doesn't exist</h1>
          ) : (
            <>
              <div className="w-100 text-center d-flex flex-column align-items-center justify-content-center">
                <div className="w-100 bg-body-tertiary">
                  <div className="w-100 d-flex align-items-start">
                    <Link
                      className="btn"
                      to={"/"}
                      style={{
                        fontSize: 26,
                        border: "none",
                        marginLeft: "12px",
                      }}
                    >
                      <BsArrowLeft />
                    </Link>
                  </div>
                  <div className="d-flex flex-column p-4">
                    <h1 className="text-xl lg:text-2xl text-uppercase lg:text-4xl">
                      {companyData.name}
                    </h1>
                    <h5 className="text-md lg:text-lg">
                      {companyData.address}, {companyData.zip}{" "}
                      {companyData.city}, {companyData.country}
                    </h5>
                    <p className="text-lg lg:text-2xl font-bold mb-0"><span className="border-r-2 border-gray-400 pe-2 me-2">{getTotalProduction().toFixed(2)} MWh</span>{getTotalRevenue().toFixed(0)} EUR</p>
                  </div>
                </div>
                {plantsDataLoaded ? (
                  <div className="bg-body w-100 pt-4  d-flex flex-column align-items-center justify-content-center">
                    <div className="w-75">{plantsView}</div>
                  </div>
                ) : (
                  "Loading"
                )}
              </div>
            </>
          )
        ) : (
          "Loading"
        )}
      </div>
    </div>
  );
};

export default Company;
