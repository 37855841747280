import axios from "axios";
import React, { useEffect, useState } from "react";
import { useIsAuthenticated, useAuthHeader } from "react-auth-kit";
import { Card } from "react-bootstrap";
import { Link } from 'react-router-dom'
import './Company.css'
import { env } from '../env'

const Home = () => {
    const isAuthenticated = useIsAuthenticated()

    const auth = useAuthHeader()

    const [companiesDataLoaded, setCompaniesDataLoaded] = useState(false)
    const [companiesView, setCompaniesView] = useState([])
    const [companiesData, setCompaniesData] = useState([])

    const config = {
        headers:{
            "Token": auth().split(' ')[1]
        }
    }

    const GetCompanies = () => {
        if (isAuthenticated()) {
            axios.get(env.REACT_APP_API_BASE + '/GetCompanies', config)
                .then(resultData => {
                    const result = resultData.data;
                    setCompaniesData(Array.from(result));
                    setCompaniesDataLoaded(true);
                    const view = result.map(company =>
                    <Card key={company.name} style={{textDecoration: 'none', border: 'none'}} className="w-75 mb-3 bg-body-tertiary custom-card" as={Link} to={'/company?id='+company.id}>
                        <Card.Body>
                            <Card.Title style={{fontSize: 32}}>{company.name}</Card.Title>
                            <Card.Subtitle className="mb-2">Address: {company.address}, {company.zip} {company.city}</Card.Subtitle>
                            <Card.Subtitle>Country: {company.country}</Card.Subtitle>
                        </Card.Body>
                    </Card>)
                    setCompaniesView(view)
                })
                .catch(error => {
                    console.error("Error fetching companies data:", error);
                    setCompaniesData([]);
                    setCompaniesDataLoaded(false);
                });
        } else {
            setCompaniesData([]);
            setCompaniesDataLoaded(false);
        }
    };

    useEffect(() => {
        GetCompanies()
    }, [])

    return(
        isAuthenticated()?
        <div className="container-fluid w-100 d-flex flex-column align-items-center text-center mt-0 p-0">
            <div className="w-100 bg-body-tertiary py-4 mb-4">
            <img src='/logo-with-text.png' className="ms-auto me-auto" style={{width:'200px', border:0, outline: 0}}/>
            </div>
            {
                companiesDataLoaded?(companiesView)
                :"Loading"
            }
        </div>
        :
        <div className="container w-100 d-flex flex-column align-items-center text-center" style={{height:'calc(100vh - 96px)', marginTop: '5rem', marginBottom: '12px'}}>
            <img src='/logo-with-text.png' style={{width:'min(300px,70vw)', border:0, outline: 0}}/>
            <h3 className="mt-2 mb-4">The new leading platform for monitoring solar plants</h3>
            <div className="row w-100 d-flex flex-column align-items-center justify-content-center px-5">
                    <a href="/login" className="btn btn-primary my-3" style={{background:'#771FFF', border:0, width: '60%', minWidth: 60}}>Log in</a>
                    <a href="/register" className="btn btn-primary" style={{background:'#771FFF', border:0, width: '60%', minWidth: 60}}>Register</a>
            </div>
        </div>
    )
}

export default Home;